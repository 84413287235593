<template>
  <div v-if="!isLoadingWork && workId">
    <b-navbar
      toggleable="lg"
      class="border-bottom border-secondary bg-white"
      style="height: 54px"
    >
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        class=""
        is-nav
      >
        <b-navbar-nav
          fill
          class="w-100 p-3"
        >
          <b-nav-item
            class="my-auto"
            :class="showmenu == 'homework' ? 'active' : null"
            :to="{
              name: 'work',
              params: { workId: workId },
            }"
            @click="$store.commit('updateWorkMenuName', null)"
          >
            <h6 class="mb-0">
              {{ workName }}
            </h6>
          </b-nav-item>

          <b-nav-item
            class="my-auto"
            :class="showmenu == 'informacoes' ? 'active' : null"
            :to="{
              name: 'work-informations',
              params: { workId: workId },
            }"
          >
            Informações
          </b-nav-item>

          <b-nav-item
            class="my-auto"
            :class="showmenu == 'orcamentos' ? 'active' : null"
            :to="{
              name: 'work-scope-index',
              params: { workId: workId },
            }"
          >
            Orçamentos
          </b-nav-item>
          <b-nav-item
            class="my-auto"
            :class="showmenu == 'suprimentos' ? 'active' : null"
            :to="{
              name: 'work-biddings',
              params: { workId: workId },
            }"
          >
            Suprimentos
          </b-nav-item>
          <b-nav-item
            class="my-auto"
            :class="showmenu == 'cronogramas' ? 'active' : null"
            :to="{
              name: 'work-schedule',
              params: { workId: workId },
            }"
          >
            Cronogramas
          </b-nav-item>
          <b-nav-item
            class="my-auto"
            :class="showmenu == 'execucao' ? 'active' : null"
            :to="{
              name: 'work-measurements',
              params: { workId: workId },
            }"
          >
            Execução
          </b-nav-item>

          <b-nav-item
            class="my-auto"
            :class="showmenu == 'financeiro' ? 'active' : null"
            :to="{
              name: 'work-invoices',
              params: { workId: workId },
            }"
          >
            Financeiro
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-navbar
      v-if="showmenu == 'informacoes'"
      toggleable="lg"
      class="bg-white"
      style="height: 54px"
    >
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        class=""
        is-nav
      >
        <b-navbar-nav
          fill
          class="w-100 p-3"
        >
          <b-nav-item
            :class="showSubmenu == 'informations-panel' ? 'active' : null"
            :to="{
              name: 'work-informations',
              params: { workId: workId },
            }"
          >
            Painel de Informações
          </b-nav-item>
          <b-nav-item
            :class="showSubmenu == 'team' ? 'active' : null"
            :to="{
              name: 'work-team',
              params: { workId: workId },
            }"
          >
            {{ $t("workteam") }}
          </b-nav-item>
          <b-nav-item
            :class="showSubmenu == 'projects' ? 'active' : null"
            :to="{
              name: 'work-projects',
              params: { workId: workId },
            }"
          >
            {{ $t("workprojects") }}
          </b-nav-item>
          <b-nav-item
            :class="showSubmenu == 'images' ? 'active' : null"
            :to="{
              name: 'work-images',
              params: { workId: workId },
            }"
          >
            {{ $t("workimages") }}
          </b-nav-item>
          <b-nav-item
            :class="showSubmenu == 'documents' ? 'active' : null"
            :to="{
              name: 'work-documents',
              params: { workId: workId },
            }"
          >
            {{ $t("workdocuments") }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-navbar
      v-if="showmenu == 'orcamentos'"
      toggleable="lg"
      class="bg-white"
      style="height: 54px"
    >
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        class=""
        is-nav
      >
        <b-navbar-nav
          fill
          class="w-100 p-3"
        >
          <b-nav-item>Painel e Escopos</b-nav-item>
          <b-nav-item
            :class="showSubmenu == 'escopos' ? 'active' : null"
            :to="{
              name: 'work-scope-index',
              params: { workId: workId },
            }"
          >
            {{ $t("workscopes") }}
          </b-nav-item>
          <!-- <b-nav-item
            :class="showSubmenu == 'budget' ? 'active' : null"
            :to="{
              name: 'work-budget',
              params: { workId: workId },
            }"
          >
            <a>{{ $t("workbudget") }}</a>
          </b-nav-item> -->
          <b-nav-item
            :class="showSubmenu == 'funds' ? 'active' : null"
            :to="{
              name: 'work-funds',
              params: { workId: workId },
            }"
          >
            <a>Verbas</a>
          </b-nav-item>
          <!-- <b-nav-item
            :class="showSubmenu == 'budget-buying' ? 'active' : null"
            :to="{
              name: 'work-budget-buying',
              params: { workId: workId },
            }"
          >
            <a>{{ $t("workbudget-buying") }}</a>
          </b-nav-item>
          <b-nav-item
            :class="showSubmenu == 'budget-measurement' ? 'active' : null"
            :to="{
              name: 'work-budget-measurement',
              params: { workId: workId },
            }"
          >
            <a>{{ $t("workbudget-measurement") }}</a>
          </b-nav-item> -->
          <b-nav-item>Gestão por Fases</b-nav-item>
          <b-nav-item>Gestão por Pav / Amb</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-navbar
      v-if="showmenu == 'cronogramas'"
      toggleable="lg"
      class="bg-white"
      style="height: 54px"
    >
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        class=""
        is-nav
      >
        <b-navbar-nav
          fill
          class="w-100 p-3"
        >
          <b-nav-item>Painel de Cronogramas</b-nav-item>
          <b-nav-item
            :to="{
              name: 'work-hours',
              params: { workId: workId },
            }"
          >
            Horário de Trabalho
          </b-nav-item>
          <b-nav-item
            :to="{
              name: 'work-schedule',
              params: { workId: workId },
            }"
          >
            {{ $t("workschedule") }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-navbar
      v-if="showmenu == 'execucao'"
      toggleable="lg"
      class="bg-white"
      style="height: 54px"
    >
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        class=""
        is-nav
      >
        <b-navbar-nav
          fill
          class="w-100 p-3"
        >
          <b-nav-item>Painel de Execução</b-nav-item>
          <b-nav-item
            :to="{
              name: 'work-hirings',
              params: { workId: workId },
            }"
          >
            {{ $t("work-hirings") }}
          </b-nav-item>
          <b-nav-item
            :to="{
              name: 'work-measurements',
              params: { workId: workId },
            }"
          >
            {{ $t("work-measurements") }}
          </b-nav-item>
          <b-nav-item
            :to="{
              name: 'work-diary',
              params: { workId: workId },
            }"
          >
            {{ $t("workdiary") }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-navbar
      v-if="showmenu == 'suprimentos'"
      toggleable="lg"
      class="bg-white"
      style="height: 54px"
    >
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        class=""
        is-nav
      >
        <b-navbar-nav
          fill
          class="w-100 p-3"
        >
          <b-nav-item
            :class="showSubmenu == 'funds' ? 'active' : null"
            :to="{
              name: 'work-funds',
              params: { workId: workId },
            }"
          >
            <a>Verbas</a>
          </b-nav-item>
          <b-nav-item
            :class="showSubmenu == 'suppliers' ? 'active' : null"
            :to="{
              name: 'work-suppliers',
              params: { workId: workId },
            }"
          >
            <a>{{ $t("work-suppliers") }}</a>
          </b-nav-item>
          <b-nav-item
            :class="showSubmenu == 'concorrencias' ? 'active' : null"
            :to="{
              name: 'work-biddings',
              params: { workId: workId },
            }"
          >
            Mapas de Concorrências
          </b-nav-item>
          <b-nav-item
            :class="showSubmenu == 'contratacoes' ? 'active' : null"
            :to="{
              name: 'work-hirings',
              params: { workId: workId },
            }"
          >
            Mapa de Contratações
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-navbar
      v-if="showmenu == 'financeiro'"
      toggleable="lg"
      class="bg-white"
      style="height: 54px"
    >
      <b-navbar-toggle target="nav-collapse" />
      <b-collapse
        id="nav-collapse"
        class=""
        is-nav
      >
        <b-navbar-nav
          fill
          class="w-100 p-3"
        >
          <b-nav-item>Painel Financeiro</b-nav-item>
          <b-nav-item
            :to="{
              name: 'work-invoices',
              params: { workId: workId },
            }"
          >
            {{ $t("work-invoices") }}
          </b-nav-item>
          <b-nav-item
            :to="{
              name: 'work-cash-flow',
              params: { workId: workId },
            }"
          >
            {{ $t("work-cash-flow") }}
          </b-nav-item>
          <b-nav-item
            :to="{
              name: 'work-electronic-invoices',
              params: { workId: workId },
            }"
          >
            {{ $t("work-electronic-invoices") }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'WorkMenu',
  components: {},
  filters: {},
  props: {

  },
  data () {
    return {
      isHovered: false

    }
  },

  computed: {
    isLoadingWork: function () {
      return this.$store.state.workData.isLoadingWork
    },
    workId: function () {
      return this.$store.state.workData.work.id
    },
    workName: function () {
      return this.$store.state.workData.work.name
    },
    showmenu: function () {
      return this.$store.state.workData.workMenuName
    },
    showSubmenu: function () {
      return this.$store.state.workData.workSubMenuName
    }
  },
  watch: {

  },
  mounted: function () {

  },
  methods: {
    onOver (hovered) {
      this.$refs[hovered].visible = true
    },
    onLeave (hovered) {
      this.$refs[hovered].visible = false
    }
  }
}
</script>

<!-- styles -->
<!-- adding scoped attribute will apply the css to this component only -->
<style lang="scss" scoped>
    .navbar-nav .active {
        border-bottom: 2px solid !important;
        border-color: blue !important;
    }
    // .custom-dropdown {
    //     &:hover {
    //       background-color:lightgray;

    // ul {
    //   visibility: visible;
    //       opacity: 1;
    //       transform: translateY(0%);
    //       transition-delay: 0s, 0s, 0.3s;
    // }
    //     }
    // }
</style>
